import { useEffect, useState } from 'react';

import Router from 'next/router';

function saveScrollPos(url) {
    const scrollPos = { x: window.scrollX, y: window.scrollY };
    // console.log('st', url, scrollPos);
    sessionStorage.setItem(url, JSON.stringify(scrollPos));
}

function restoreScrollPos(url) {
    const scrollPos = JSON.parse(sessionStorage.getItem(url));
    // console.log('s', url, scrollPos);
    if (scrollPos) {
        window.scrollTo(scrollPos.x, scrollPos.y);
    }
}

export default function useScrollRestoration(router) {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.onpopstate = function (event) {
                window.history.scrollRestoration = 'manual';
                setScroll(true);
            };

            const onRouteChangeStart = () => {
                saveScrollPos(router.asPath);
            };

            const onRouteChangeComplete = url => {
                if (scroll) {
                    restoreScrollPos(url);
                    setScroll(false);
                }
            };

            Router.events.on('routeChangeStart', onRouteChangeStart);
            Router.events.on('routeChangeComplete', onRouteChangeComplete);

            return () => {
                Router.events.off('routeChangeStart', onRouteChangeStart);
                Router.events.off('routeChangeComplete', onRouteChangeComplete);
            };
        }
    }, [router]);
}
