import { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export function AppWrapper({ children }) {
    let sharedState = {
        deviceType: () => {
            return null;
        }
    };

    const [context, setContext] = useState(sharedState);

    return (
        <AppContext.Provider
            value={{
                toggleData: async (slug = null) => {
                    setContext(slug);
                    return context;
                }
            }}>
            {children}
        </AppContext.Provider>
    );
}

export function useAppContext() {
    return useContext(AppContext);
}
