import pick from 'lodash/pick';
import Head from 'next/head';
import * as React from 'react';
import { useThemeGeneralSettingsQuery } from '../generated/graphql';
import { hexToRGB } from '../utils/hexToRGB';
// import useDonationView from '@/hooks/useDonationView';

const GlobalAppThemeContext = React.createContext();

function GlobalAppThemeProvider({ variant = null, children }) {
    // Retrieving the Theme Settings data from WP
    const { data } = useThemeGeneralSettingsQuery({ fetchPolicy: 'cache-only' });
    const themeGeneralSettings = data?.themeGeneralSettings;
    const settings = themeGeneralSettings?.themeOptions;
    const { footerCustomization } = themeGeneralSettings?.themeOptions || {};
    const boxShadows = settings?.boxShadows;

    let themeOptions = React.useMemo(() => {
        return settings || {};
    }, [settings]);
    let extraColorCSS = '';

    // No variant in our code right now
    if (variant) {
        let variantThemeOptions = (settings?.functions || []).find(themeSettings => {
            return themeSettings.functionName === variant;
        });
        if (variantThemeOptions) {
            themeOptions = variantThemeOptions;
        }
    }
    // const donationView = useDonationView();

    const options = React.useMemo(() => {
        const activatedThemeFields = [
            'siteIcon',
            'siteLogo',
            'siteLogoAlt1',
            'siteLogoAlt2',
            'desktopFonts',
            'siteNeutralColor',
            'sitePrimaryColor',
            'siteSecondaryColor',
            'mobileFonts',
            'extraColors',
            'buttons',
            'noLightboxUrls'
        ];

        const footer = footerCustomization || {};
        // Jeffrey will tell you when to change this variable
        const specialTimesToShowLightbox = false;

        // Buya: can you give more details on how this works?
        if (themeOptions) {
            const colors = Object.keys(themeOptions).reduce((prev, key) => {
                const isColor = key.toLowerCase().includes('color');
                if (!isColor) return prev;

                if (typeof themeOptions[key] === 'object') {
                    let extraColors;

                    themeOptions[key]?.forEach(function (el) {
                        extraColors = {
                            ...extraColors,
                            [el.name]: el.color
                        };
                    });
                    return {
                        ...prev,
                        extraColors
                    };
                }

                return {
                    ...prev,
                    [key]: themeOptions[key] ? hexToRGB(themeOptions[key]) : '#fff'
                };
            }, {});
            return {
                colors,
                ...pick(themeOptions, activatedThemeFields),
                footer,
                specialTimesToShowLightbox
            };
        }
        return { ...pick(themeOptions, activatedThemeFields), footer, specialTimesToShowLightbox };
    }, [themeOptions, footerCustomization]);

    const extraColors = options?.colors?.extraColors;
    const { desktopFonts, buttons } = options;

    // Adding button styles and classes
    const buttonMap = buttons?.map((button, index) => {
        const { className, backgroundColor, textColor, addHoverOptions, hoverBackgroundColor, hoverTextColor } = button;
        const buttonClassName = `.btn-${className}`;
        const hoverTextColorStyle = `{color: ${hoverTextColor} !important}`;
        const hoverBackgroundColorStyle = `{background-color: ${hoverBackgroundColor} !important; text-decoration-line: none !important; transition: all .2 ease}`;
        // const darkTextColorStyle = `{color: ${darkTextColor}}`;
        // const darkBackgroundColorStyle = `{background-color: ${darkBackgroundColor}}`;
        const hoverStyles =
            addHoverOptions && `${buttonClassName}:hover ${hoverTextColor ? { hoverTextColorStyle } : ''} ${hoverBackgroundColor ? hoverBackgroundColorStyle : ''};`;
        // const darkStyles = addDarkOptions && `${buttonClassName} ${darkTextColor ? darkTextColorStyle : ''} ${darkBackgroundColor ? darkBackgroundColorStyle : ''}`;

        return (
            <React.Fragment key={index}>
                <style>
                    {`
            ${buttonClassName} {
                padding: .5rem 1.25rem;
                border-radius: 100px;
                color: ${textColor} !important;
                background-color: ${backgroundColor} !important;
            }
            ${hoverStyles}
        `}
                </style>
            </React.Fragment>
        );
    });

    const rootDesktopFonts = (desktopFonts || []).reduce((acc, font) => {
        let fontFamilies = 'escrow-banner, Times New Roman, serif';

        if (font.fontFamily === 'proxima-nova') fontFamilies = 'proxima-nova, Arial, sans-serif';

        if (font?.tag) {
            acc[font.tag] = `normal ${font.weight} ${font.fontSizeInPx}px ${fontFamilies}`;
        }
        return acc;
    }, {});

    // const rootMobileFonts = (mobileFonts || []).reduce((acc, font) => {
    //     if (font?.tag) {
    //         acc[font.tag] = `normal ${font.weight} ${font.fontSizeInPx}px ${font.fontFamily}`;
    //     }
    //     return acc;
    // }, {});

    // desktopFonts?.map((font) => {
    //     desktopFontsCSS += `--${font?.tag}-font: ${font?.fontFamily};`;
    // });

    if (typeof window !== 'undefined') {
        const extraColorArray = extraColors && Object.entries(extraColors);
        if (extraColorArray !== undefined) {
            extraColorArray.map(color => {
                extraColorCSS += `--color-${color[0]}: ${color[1] || '0, 0, 0'};\n`;
            });
        }
    }

    return (
        <GlobalAppThemeContext.Provider value={options}>
            <Head>{options?.icon?.sourceUrl ? <link rel="shortcut icon" href={options.icon.sourceUrl} /> : null}</Head>
            {children}

            {/* eslint-disable-next-line */}
            <style jsx global>
                {`
                    :root {
                        --color-primary: ${options?.sitePrimaryColor || '#000'};
                        --color-secondary: ${options?.siteSecondaryColor || '#000'};
                        --color-neutral: ${options?.siteNeutralColor || '#000'};
                        ${extraColorCSS && extraColorCSS}
                        ${rootDesktopFonts &&
                        Object.entries(rootDesktopFonts)
                            .map(font => `--${font[0]}-font: ${font[1]};`)
                            .join('\n')}
                        ${boxShadows && boxShadows?.map(boxShadow => `--shadow-${boxShadow.shadowClassName}: ${boxShadow.shadowStyle};`).join(', '.replace(',', ''))}
                    }
                `}
            </style>
            {buttonMap}
        </GlobalAppThemeContext.Provider>
    );
}

export function useAppTheme() {
    return React.useContext(GlobalAppThemeContext);
}

export default GlobalAppThemeProvider;
