// import { AddressField, AddressInputProperty } from './../generated/graphql';
import { useMemo } from 'react';
// import dynamic from 'next/dynamic';
import { ApolloClient, InMemoryCache, NormalizedCacheObject, HttpLink } from '@apollo/client';
// import { offsetLimitPagination } from '@apollo/client/utilities';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
// import { usePregeneratedHashes } from 'graphql-codegen-persisted-query-ids/lib/apollo';

import introspectionQueryResultData from '../../fragmentTypes.json';
import { sha256 } from 'crypto-hash';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';
const windowApolloState = typeof window !== 'undefined' ? window[APOLLO_STATE_PROP_NAME] : {};
let apolloClient: ApolloClient<NormalizedCacheObject>;
const token = process.env.WORDPRESS_AUTH_REFRESH_TOKEN;
// const hashes = require('../../persisted-query-ids/client.json');

const persistedLink = createPersistedQueryLink({
    // useGETForHashedQueries: true,
    // generateHash: usePregeneratedHashes(hashes),
    sha256,
    disable: ({ graphQLErrors }) => {
        // if the server doesn't support persisted queries, don't try anymore
        if (graphQLErrors && graphQLErrors.some(({ message }) => message === 'PersistedQueryNotSupported')) {
            return true;
        }

        return null;
    }
}).concat(
    new HttpLink({
        uri: process.env.NEXT_PUBLIC_GRAPHQL,
        credentials: 'same-origin'
        // headers: { authorization: token ? `Bearer ${token}` : '' }
    })
);

function createApolloClient() {
    return new ApolloClient({
        ssrMode: typeof window === 'undefined',
        link: persistedLink,
        connectToDevTools: typeof window !== 'undefined',
        cache: new InMemoryCache({
            possibleTypes: introspectionQueryResultData.possibleTypes,
            typePolicies: {
                TextField: {
                    merge: true,
                    keyFields: ['formId', 'id', '__typename']
                },
                EmailField: {
                    keyFields: ['formId', 'id', '__typename']
                },
                HtmlField: {
                    keyFields: ['formId', 'id', '__typename']
                },
                RadioField: {
                    keyFields: ['formId', 'id', '__typename']
                },
                CheckboxField: {
                    keyFields: ['formId', 'id', '__typename']
                },
                TextAreaField: {
                    keyFields: ['formId', 'id', '__typename']
                },
                SelectField: {
                    keyFields: ['formId', 'id', '__typename']
                },
                NumberField: {
                    keyFields: ['formId', 'id', '__typename']
                },
                HiddenField: {
                    keyFields: ['formId', 'id', '__typename']
                },
                PhoneField: {
                    keyFields: ['formId', 'id', '__typename']
                },
                RootQuery: {
                    queryType: true
                },
                RootMutation: {
                    mutationType: true
                }
            }
        }).restore(windowApolloState)
    });
}

export function initializeApollo(initialState = null) {
    const _apolloClient = apolloClient ?? createApolloClient();

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract();
        // Merge the existing cache into data passed from getStaticProps/getServerSideProps
        const data = merge(existingCache, initialState, {
            // combine arrays using object equality (like in sets)
            arrayMerge: (destinationArray, sourceArray) => [...sourceArray, ...destinationArray.filter(d => sourceArray.every(s => !isEqual(d, s)))]
        });

        // Restore the cache with the merged data
        _apolloClient.cache.restore(data);
        // _apolloClient.cache.restore(initialState);
    }

    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return _apolloClient;
    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient;

    return _apolloClient;
}

export function addApolloState(client, pageProps) {
    if (pageProps?.props) {
        pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
    }

    return pageProps;
}

export function useApollo(pageProps) {
    const state = pageProps[APOLLO_STATE_PROP_NAME];
    const store = useMemo(() => initializeApollo(state), [state]);
    return store;
}
