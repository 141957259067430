const getConfig = require('next/config')?.default;

const publicRuntimeConfig = getConfig()?.publicRuntimeConfig || {};

const isProduction = () => {
    const env = process.env.NEXT_PUBLIC_WP;
    if (!env) {
        return false;
    }
    const productionDomains = ['https://live.jewsforjesus.org'];
    const production = productionDomains.some(domain => env.includes(domain));
    return production;
};

module.exports = isProduction;
